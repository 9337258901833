import React from 'react'

export default function Footer() {
  return (
    <footer>
    <div>
        Все права защищены &copy;
    </div>
    </footer>
  )
}
