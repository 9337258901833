import Header from "./components/Header";
import Footer from "./components/Footer";
import React from "react";
import Items from "./components/Items";

class App extends React.Component {
  constructor (props){
    super(props)
    this.state ={
      orders:[],
      items: [{
        id:1,
        title:'блокнот',
        img:'блокнот черный.jpg',
        desc: 'Модный блокнот',
        price: '1000'
      },
      {
        id:2,
        title:'ручка',
        img:'ручка.jpg',
        desc: 'Крутая ручка',
        price: '1500'
      },
      {
        id:3,
        title:'пауербанк',
        img:'пауербанк.jpg',
        desc: 'Мощный паувербанк',
        price: '200'
      },
      {
        id:4,
        title:'рюкзак',
        img:'рюкзак 1.jpg',
        desc: 'Крутой рюкзак',
        price: '500'
      },
      {
        id:5,
        title:'термос',
        img:'термос чб 1.jpg',
        desc: 'Крутой термос',
        price: '300'
      },
      {
        id:6,
        title:'термос',
        img:'термос чб 2.jpg',
        desc: 'Крутой термос',
        price: '1000'
      },
      {
        id:7,
        title:'бокал',
        img:'бокал.jpg',
        desc: 'Крутой бокал',
        price: '1500'
      },
      {
        id:8,
        title:'кружка',
        img:'кружка белая 1.jpg',
        desc: 'Крутая кружка',
        price: '2000'
      },
      {
        id:9,
        title:'кружка',
        img:'кружка черная 1.jpg',
        desc: 'Крутая кружка',
        price: '500'
      },
      {
        id:10,
        title:'кепка',
        img:'кепка 1.jpg',
        desc: 'Крутая кепка',
        price: '300'
      },
      {
        id:11,
        title:'кепка',
        img:'кепка 2.jpg',
        desc: 'Крутая кепка',
        price: '1000'
      },
      {
        id:12,
        title:'кепка',
        img:'кепка 3.jpg',
        desc: 'Крутая кепка',
        price: '1500'
      },
      {
        id:13,
        title:'футболка',
        img:'футболка белая 1.jpg',
        desc: 'Крутая футболка',
        price: '200'
      },
      {
        id:14,
        title:'футболка',
        img:'футболка белая 2.jpg',
        desc: 'Крутая футболка',
        price: '500'
      },
      {
        id:15,
        title:'футболка',
        img:'футболка белая 3.jpg',
        desc: 'Крутая футболка',
        price: '300'
      },
      {
        id:16,
        title:'футболка',
        img:'футболка черная 1.jpg',
        desc: 'Крутая футболка',
        price: '1000'
      },
      {
        id:17,
        title:'футболка',
        img:'футболка черная 2.jpg',
        desc: 'Крутая футболка',
        price: '1500'
      },
      {
        id:18,
        title:'футболка',
        img:'футболка черная 3.jpg',
        desc: 'Крутая футболка',
        price: '2000'
      },
      {
        id:19,
        title:'худи',
        img:'худи белая 1.jpg',
        desc: 'Крутая худи ',
        price: '500'
      },
      {
        id:20,
        title:'худи',
        img:'худи белая 2.1.jpg',
        desc: 'Крутая худи ',
        price: '500'
      },
      {
         id:21,
        title:'худи',
        img:'худи белая 2.jpg',
        desc: 'Крутая худи ',
        price: '500'
      },
      {
        id:22,
        title:'худи',
        img:'худи чб 1.jpg',
        desc: 'Крутая худи ',
        price: '500'
      },
      {
        id:23,
        title:'худи',
        img:'худи чб 2.1.jpg',
        desc: 'Крутая худи ',
        price: '5000'
      },
      {
        id:24,
        title:'худи',
        img:'худи чб 2.jpg',
        desc: 'Крутая худи ',
        price: '5000'
      },
      {
        id:25,
        title:'худи',
        img:'худи черная 1.jpg',
        desc: 'Крутая худи ',
        price: '5000'
      },
      {
        id:26,
        title:'худи',
        img:'худи черная 2.1.jpg',
        desc: 'Крутая худи ',
        price: '5000'
      },
      {
        id:27,
        title:'худи',
        img:'худи черная 2.jpg',
        desc: 'Крутая худи ',
        price: '5000'
      },
      {
        id:28,
        title:'худи',
        img:'худи чс 1.jpg',
        desc: 'Крутая худи ',
        price: '5000'
      },
      {
        id:29,
        title:'худи',
        img:'худи чс 2.1.jpg',
        desc: 'Крутая худи ',
        price: '5000'
      },
      {
        id:30,
        title:'худи',
        img:'худи чс 2.jpg',
        desc: 'Крутая худи ',
        price: '5000'
      }
    ]
    }
    this.addToOrder = this.addToOrder.bind(this)
  }
  render(){
  return (
    <div className="wrapper">
      <Header orders={this.state.orders}/>
      <Items items={this.state.items} onAdd={this.addToOrder}/>
      <Footer />
    </div>
  );
}
  addToOrder(item){
    let isInArray = false
    this.state.orders.forEach (el => {
      if(el.id === item.id)
      isInArray = true
    })

    if(!isInArray)
    this.setState({orders:[...this.state.orders, item]})
  }
}

export default App;
